<template>
  <div id="app">
    <div :class="!flag ? 'view' : 'view1'">
      <!-- <div class="view" :style="style" :class="['homeNav', { color: color }]"> -->
      <div class="titles">
        <div class="title_img"><img src="./static/title.png" style="width: 60px;height: 68px;" /></div>
        <div class="title" style="margin-left: 20px;">智宜居</div>
        <nav>
          <router-link to="/">首页</router-link>
          <router-link to="/technology">核心技术</router-link>
          <router-link to="/scene">场景体验</router-link>
          <router-link to="/partner">事业合伙人</router-link>
          <router-link to="/about">关于我们</router-link>
          <router-link to="">400-007-7507</router-link>
        </nav>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'IndexView',
  data() {
    return {
      style: { backgroundColor: "" },
      color: false,
      scrollTop: 0,
      flag: false,
    };
  },
  async created() {
    // 开始请求题库数据
    // let res = await this.$axios.infos;
    // console.log(res);
    // this.datas = res;
    // console.log(this.datas);
  },
  async activated() {
    if (this.refresh) {
      // 开始请求题库数据
      // let res = await this.$axios.infos;
      // console.log(res);
      // this.datas = res;
    }
  },
  methods: {
    gundong() {
      var dis = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if (dis > 120) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("scrollTop.length",scrollTop);

      if (scrollTop >= 300) {
        this.style.backgroundColor = `rgba(255, 255, 255,${scrollTop / (scrollTop + 80)
          }) `;
        this.color = "#FFFFFF";
      } else if (scrollTop == 0) {
        this.style.backgroundColor = `rgba(0,0,0,0.3)`;
        this.color = "#2c3e50";
      }
      // 轮播  增加动画效果

    },

  },
  computed: {

  },
  components: {

  },
  destroyed() {
    window.removeEventListener("scroll", this.gundong);
  },
  mounted() {
    window.addEventListener("scroll", this.gundong);
    // window.addEventListener("scroll", this.handleScroll);
    // console.log(this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

.titles {
  width: 1250px;
  margin: auto;
  /* background-color: pink; */
  overflow: hidden;
  display: flex;
  white-space: nowrap;
}

nav {
  display: flex;
  padding: 34px;
}

nav a {
  font-weight: bold;
  margin-left: 60px;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  /* color: #FFFFFF; */
  text-decoration: none;
  white-space: nowrap;
}

.view nav a {
  color: #fff;
}

.view1 nav a {
  color: #000;
}

nav a:first-of-type {
  margin-left: 176px;
  white-space: nowrap;
}

.view {
  align-items: center;
  display: flex;
  width: 100%;
  height: 90px;
  /* height: 90px; */
  background-color: rgba(0, 0, 0, 0.49);
  /* opacity: 0.49; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: .4s;
  color: #fff !important;
  /* background-color: rgba(255, 255, 255, 0.3); */
}
.view1 .title_img {
    display: inline-block;
    width: 60px;
    height: 68px;
    float: left;
    margin-right: 20px;
    margin-top: 12px;
}
.view1 {
  align-items: center;
  color: #000 !important;
  display: flex;
  transition: .4s;
  width: 100%;
  height: 90px;
  /* height: 90px; */
  background-color: rgba(255, 255, 255, 0.69);
  /* opacity: 0.49; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* background-color: rgba(255, 255, 255, 0.3); */
}

#app {
  width: 100%;
  /* height: 90px; */
  /* height: 90px; */
  /* background-color: rgba(0,0,0,.3);
  /* opacity: 0.49; */
  /* position: fixed;
  top: 0;
  left: 0; */
}

#app .title {
  display: flex;
  float: left;
  height: 100%;
  line-height: 90px;
  font-size: 36px;
  font-family: zcoolqingkehuangyouti;
  font-weight: 400;
  /* color: #FFFFFF; */
}

#app nav {
  display: inline-block;
  float: left;
  display: flex;
  justify-content: center;
}

#app nav router-link {
  margin-right: 60px;
}

.view .title_img {
  display: inline-block;
  width: 60px;
  height: 68px;
  float: left;
  margin-right: 20px;
  margin-top: 12px;
}

.view .title_img img {
  width: 100%;
  height: 100%;
}

.content_a10 {
  width: 1920px;
  height: 491px;
  background: #333233;
  margin-top: 99px;
 
}

.content_a10 .a10_s11 {
  display: inline-block;
  float: left;
  margin-left: 360px;
  margin-top: 64px;
}

.content_a10 .a10_s11 div:first-of-type {
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 63px;
}

.content_a10 .a10_s11 div:nth-of-type(2) {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #999999;
  line-height: 60px;
}

.content_a10 .a10_s11 div:nth-of-type(3) {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #999999;
  line-height: 60px;
}

.content_a10 .a10_s1 {
  display: inline-block;
  float: left;
  margin-left: 196px;
  margin-top: 64px;
}

.content_a10 .a10_s1 div:first-of-type {
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 63px;
}

.content_a10 .a10_s1 div:nth-of-type(2) {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #999999;
  line-height: 60px;
}

.content_a10 .a10_s1 div:nth-of-type(3) {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #999999;
  line-height: 60px;
}

.a10_s2 {
  float: left;
  margin-left: 196px;
  margin-top: 71px;
}

.a10_s2 .a1 .s2 {
  display: inline-block;
}

.a10_s2 .a1 .s2:first-of-type {
  border-right: 1px solid white;
  padding-right: 5px;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #FFFFF4;
}

.a10_s2 .a1 .s2:nth-of-type(2) {
  font-size: 34px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 36px;
}

.a10_s2 .a2 {
  display: inline-block;
  width: 261px;
  height: 261px;
  background: #FFFFFF;
  border: 4px solid #FFFFFF;
  margin-top: 32px;
}

.a10_s2 .a2 img {
  width: 203px;
  height: 203px;
  margin-top: 26px;
}
nav a:hover{
  color: #C39434;
}
</style>
