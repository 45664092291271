import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'home',
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/technology',
    // name: 'technology',
    component: () => import('../views/TechnologyView.vue')
  },
  {
    path: '/scene',
    name: 'scene',
    component: () => import('../views/SceneView.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('../views/PartnerView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/tel',
    name: 'tel',
    component: () => import('../views/TelView.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
